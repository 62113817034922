import _ from 'lodash'

export default {
  saveFile(arr) {
    const data = JSON.stringify(arr)
    const blob = new Blob([data], { type: 'text/plain' })
    const e = document.createEvent('MouseEvents'),
      a = document.createElement('a')
    a.download = 'subdistrict.json'
    a.href = window.URL.createObjectURL(blob)
    a.dataset.downloadurl = ['text/json', a.download, a.href].join(':')
    e.initEvent(
      'click',
      true,
      false,
      window,
      0,
      0,
      0,
      0,
      0,
      false,
      false,
      false,
      false,
      0,
      null
    )
    a.dispatchEvent(e)
  },

  getProvince(data) {
    let result = _.chain(data)
      .groupBy('CH_ID')
      .map((v) => {
        let changwat_t = this.removePrefix(
          _.get(_.find(v, 'CHANGWAT_T'), 'CHANGWAT_T')
        )

        return {
          id: _.get(_.find(v, 'CH_ID'), 'CH_ID'),
          name: changwat_t,
        }
      })
      .value()

    return _.orderBy(result, 'name')
  },

  getAmphoe(data, changwatID) {
    let result = _.chain(data)
      .filter((item) => item.CH_ID == changwatID)
      .groupBy('AM_ID')
      .map((v) => {
        let amphoe_t = this.removePrefix(
          _.get(_.find(v, 'AMPHOE_T'), 'AMPHOE_T')
        )

        return {
          id: _.get(_.find(v, 'AM_ID'), 'AM_ID'),
          name: amphoe_t,
        }
      })
      .value()

    return _.orderBy(result, 'name')
  },

  getTambon(data, amphoeID) {
    let result = _.chain(data)
      .filter((item) => item.AM_ID == amphoeID)
      .groupBy('TA_ID')
      .map((v) => {
        let tambon_t = this.removePrefix(
          _.get(_.find(v, 'TAMBON_T'), 'TAMBON_T')
        )

        return {
          id: _.get(_.find(v, 'TA_ID'), 'TA_ID'),
          name: tambon_t,
        }
      })
      .value()

    return _.orderBy(result, 'name')
  },

  removePrefix(text) {
    const prefixPattern = /(เขต|แขวง|จังหวัด|อำเภอ|ตำบล|อ\.|ต\.|จ\.)/g
    let string = text.replace(/\s+/g, '')
    string = string.replace(prefixPattern, '')
    return string
  },
}
